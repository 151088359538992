@tailwind base;
@tailwind components;
@tailwind utilities;


.default-shadow {
  -webkit-box-shadow: 0px 0px 4px 2px rgba(172, 172, 172, 1);
  -moz-box-shadow: 0px 0px 4px 2px rgba(172, 172, 172, 1);
  box-shadow: 0px 0px 4px 2px rgba(172, 172, 172, 1);
}

.z-top {
  z-index: 9999999 !important;
}